import React, { useState, useEffect } from 'react';
import Modal from '../components/model';
import ModalForm from '../components/modelform';
import TechnologySection from '../components/banner_lines';
import emobomo from '../images/banner.png';


const Hero3 = ({ openModal }) => {
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const words = ["CRM", "ERP", "Mobile Apps", "Build Your Own Website", "Web Development", "UI/UX Design"];

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        }, 2000);
 
        return () => clearInterval(interval);
    }, []);

    return (
        <section className="business_consulting_hero_section section_decoration mb-0 bg-light">
            <div className="container">
                <div className="row align-items-center justify-content-lg-between">
                    <div className="col-lg-5">
                        <div className="business_consulting_hero_content">
                            <h2 className='pb-4 translate-loop typing'>
                                A Product of
                                <a
                                    href="https://emobomo.com/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="site_link ms-3"
                                >
                                    <img 
                                    src={emobomo} 
                                    alt="Appshive" 
                                    style={{ maxHeight: '30px', marginLeft: '10px' }} 
                                    />
                                </a>
                            </h2>
                            <h3>Appshive</h3>
                            <h1 className="typing" style={{ fontSize: "clamp(30px, 40px, 50px)" }}>
            {words[currentWordIndex].split('').map((char, index) => (
                <span key={index} className="character" style={{ animationDelay: `${index * 0.1}s` }}>
                    {char}
                </span>
            ))}
        </h1>
                            <p>The digital journey starts here</p>
                            <button
                                onClick={() => openModal("Hero Button")} // Trigger modal with context
                                className="btn btn-primary"
                            >
                                <span className="btn_label2">Start with us</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6">

                        
                        <TechnologySection openModal={openModal}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Hero3;
