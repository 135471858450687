import React from 'react'
// import { Link } from 'react-router-dom'
import emobomo from '../images/footer.png';

import Footeraddress from '../components/footeraddress';

// const ClickHandler = () => {
//     window.scrollTo(10, 0);
// }


const BusinessFooter = (props) => {
    return (
        <footer className="site_footer footer_layout_3 bg-white">
        <div className="footer_main_content ">
          <div className="container">
           

            <div className="row justify-content-lg-between pt-lg-4">
                <div className="col-md-3 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Connect with call </h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        <img width="26" height="26" src="https://img.icons8.com/color/48/india.png" alt="India" />
                          <a href="tel:7799360999" >
                            <span className="icon_list_text">
                           +91 7799360999
                            </span>
                          </a>
                        </li>
                        <li>
                        <img width="26" height="26" src="https://img.icons8.com/color/48/usa.png"  alt="usa" />
                          <a href="tel:+1 (209) 717-9580" >
                            <span className="icon_list_text">
                           +1 (209) 717-9580
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title">Start a Conversation</h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        <img width="26" height="26" src="https://img.icons8.com/ios-filled/50/007553/apple-mail.png"  alt="mail"  />
                          <a href="mailto:info@emobomo.com" >
                            <span className="icon_list_text">
                            info@emobomo.com
                            </span>
                          </a>
                        </li>
                      </ul> 
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title"><img className="me-3" width="30" height="30" src="https://img.icons8.com/color/48/india.png"  alt="Indai"  /> INDIA </h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        
                          <a href="https://maps.app.goo.gl/aFtEhDMiCtj7EZP28" target='_blank' rel="noreferrer">
                            <span className="icon_list_text">
                            Emobomo Soft Pvt. Ltd., <br></br>Manjeera Trinity Corporate, 604 - ARV Work Space , KPHB - JNTU ROAD, HYDERABAD - 500072
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-md-3 col-sm-6">
                    <div className="footer_widget">
                      <h3 className="footer_info_title"><img className="me-3" width="30" height="30"  alt="Usa"  src="https://img.icons8.com/color/48/usa.png" /> USA </h3>
                      <ul className="icon_list unordered_list_block">
                        <li>
                        
                          <a href="https://maps.app.goo.gl/r2jngZ9k2fjSSqW7A" target='_blank' rel="noreferrer">
                            <span className="icon_list_text">
                            Emobomo Inc, <br></br>644 Bacarra Street Lathrop CA 95330
                            </span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-6  d-none">
                    <div className="footer_widget">
                    {/* <h3 className="footer_info_title">Our Office Locations</h3> */}
                    <Footeraddress/>
                    
                    </div>
                  </div>
                </div>
              </div>
            </div>
        <div className="footer_bottom bg-primary text-white">
          <div className="container d-md-flex align-items-md-center justify-content-md-between">
            <p className="copyright_text m-0">
              Copyright © 2024 Appshive, All rights reserved.
            </p>
            <p className="copyright_text m-0">
              Product of <a href="https://emobomo.com/" target="_blank" rel="noopener noreferrer"><img 
                                    src={emobomo} 
                                    alt="Appshive" 
                                    style={{ maxHeight: '30px', marginLeft: '10px' }} 
                                    /></a>
            </p>
          </div>
        </div>
      </footer>
    )
}

export default BusinessFooter;