import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';

const addresses = {
    india: {
        title: "India Office",
        address: "Emobomo Soft Private Limited, <br> Manjeera Trinity Corporate, 604 - ARV Work Space , KPHB - JNTU ROAD, HYDERABAD - 500072 ",
        // phone: "+91-123-456-7890",
    },
    usa: {
        title: "USA Office",
        address: "Emobomo Soft Private Limited, 644 Bacarra Street Lathrop CA 95330",
        // phone: "+1-987-654-3210",
    },
};

const Footeraddress = () => {
    const [selectedAddress, setSelectedAddress] = useState('india');

    const toggleAddress = (address) => {
        setSelectedAddress(address);
    };

    return (
        <div className="">
            
            <div className="tab_block_wrapper new">
                <Nav tabs className="nav mb-2">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: selectedAddress === 'india' })}
                            onClick={() => toggleAddress('india')}
                        >
                           <img className="me-3" width="30" height="30" src="https://img.icons8.com/color/48/india.png" alt="india" /> India
                        </NavLink>
                    </NavItem> 
                    <NavItem>
                        <NavLink
                            className={classnames({ active: selectedAddress === 'usa' })}
                            onClick={() => toggleAddress('usa')}
                        >
                           <img  className="me-3" width="30" height="30" src="https://img.icons8.com/color/48/usa.png" alt="usa" /> USA
                        </NavLink>
                    </NavItem>
                </Nav>
                        <p className="icon_list_text">
                       {addresses[selectedAddress].address}
                        </p>
            </div>
        </div>
    );
};

export default Footeraddress;
