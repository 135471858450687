import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/applogo.png';
// import emobomo from '../images/emo.png';
import MobileMenu from '../components/mobile';
import Modal from '../components/model';
import ModalForm from '../components/modelform';

const Header = () => {
    const [mobailActive, setMobailState] = useState(false);
    const [isSticky, setSticky] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 80) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <header className="site_header site_header_1">
            <div className={`header_bottom stricky ${isSticky ? 'stricked-menu stricky-fixed' : ''}`}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-3 col-lg-2 col-8">
                            <div className="site_logo">
                                <Link onClick={ClickHandler} className="site_link" to="/">
                                    <img src={logo} alt="Appshive"  />
                                </Link>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7 col-2 position-relative1">
                            <nav className="main_menu navbar navbar-expand-lg main_menu navbar navbar-expand-lg d-flex justify-content-center align-items-center">
                                {/* <h4 className="d-none translate-loop">
                                A Product of  
                                <a href="https://emobomo.com/" target="_blank" className="site_link" >
                                    <img 
                                    src={emobomo} 
                                    alt="Appshive" 
                                    style={{ maxWidth: '150px', marginLeft: '20px' }} 
                                    />
                                </a>
                                </h4> */}
                            </nav>
                            <nav className="main_menu navbar navbar-expand-lg d-none">
                                <div className="main_menu_inner collapse navbar-collapse justify-content-lg-center" id="main_menu_dropdown">
                                <ul className="main_menu_list unordered_list justify-content-center">
                                    <li><Link onClick={ClickHandler} to="/">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">Our Works</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">Products</Link></li>
                                    <li><Link onClick={ClickHandler} to="/">Contact</Link></li>
                                </ul>
                                </div>
                            </nav>
                            </div>

                        <div className="col-xl-3 col-lg-3 col-2">
                            <ul className="header_btns_group unordered_list justify-content-end">
                                <li>
                                    <button className="mobile_menu_btn" onClick={() => setMobailState(!mobailActive)} type="button">
                                        <i className="far fa-bars"></i>
                                    </button>
                                </li>
                                <li>
                                    <button className="btn btn-outline-light new-white"  onClick={() => openModal("Hero Button")}>
                                        <span className="btn_label" data-text="Get Started">Get Started</span>
                                        <span className="btn_icon">
                                            <i className="fa-solid fa-arrow-up-right"></i>
                                        </span>
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="mobail-wrap">
                    <div className={`mobail-menu ${mobailActive ? "active" : ""}`}>
                        <MobileMenu />
                    </div>
                    <div className="xb-header-menu-backdrop" onClick={() => setMobailState(false)}></div>
                </div>
            </div>

            {/* Modal for Get Started */}
            <Modal show={showModal} handleClose={closeModal}>
                <ModalForm />
            </Modal>
        </header>
    );
};

export default Header;
