import React from 'react';

const Modal = ({ show, handleClose, children }) => {
    const modalStyles = {
        display: show ? 'flex' : 'none',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1050, // Ensure it's above other elements
        overflow: 'hidden', // Prevent scrolling when modal is open
    };
    
    const modalContentStyles = {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '90%',
        maxWidth: '800px',
        maxHeight: '90%', // Limit the height
        overflowY: 'auto', // Enable vertical scrolling
        position: 'relative',
        zIndex: 1051, // Ensure modal content is above background overlay
    };

    const closeButtonStyles = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '20px',
        cursor: 'pointer',
    };

    return (
        <div style={modalStyles} onClick={handleClose}>
            <div style={modalContentStyles} onClick={(e) => e.stopPropagation()}>
                <button style={closeButtonStyles} onClick={handleClose}>×</button>
                {children}
            </div>
        </div>
    );
};

export default Modal;
