import React, { useState, useEffect } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import axios from 'axios';
import emobomo from '../images/emo.png';
import arrow from '../images/success.gif';

const ContactForm = ({ selectedService , selectedTechnology }) => {
    const [forms, setForms] = useState({
        name: '',
        email: '',
        phone: '',
        company: '',
        city: '',
        country: '',
        service: selectedTechnology  || '', 
        
    });
    // console.log(selectedService)
    
    const [touched, setTouched] = useState({});
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false); 
    const [isLoading, setIsLoading] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const validator = new SimpleReactValidator();

    useEffect(() => {
        if (selectedService && selectedService !== forms.service) {
            setForms((prevForms) => ({
                ...prevForms,
                service: selectedService,
            }));
        }
    }, [selectedService, forms.service]); // Dependency on both selectedService and forms.service
    // Handle form field changes
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setForms({ ...forms, [name]: value });

        // Log the service value every time it changes
        if (name === 'service') {
            console.log('Service changed to:', value);
        }
    };

    // Handle when a field is blurred (leaving focus)
    const blurHandler = (e) => {
        const { name } = e.target;
        setTouched({ ...touched, [name]: true });
    };

    // Check if an error message should be shown for a field
    const shouldShowError = (fieldName) => touched[fieldName] || isFormSubmitted;

    // Clear the form fields and reset the state
    const clearForm = () => {
        setForms({
            name: '',
            email: '',
            phone: '',
            company: '',
            city: '',
            country: '',
            service: '',
        });
        setTouched({});
        setValidationMessage('');
        validator.hideMessages();
        setIsFormSubmitted(false);
    };

    // Handle form submission
    const submitHandler = async (e) => {
        e.preventDefault();
        setIsFormSubmitted(true);

        // Check if all fields are filled
        const areAllFieldsFilled = Object.values(forms).every((value) => value.trim() !== '');

        if (areAllFieldsFilled && validator.allValid()) {
            setIsLoading(true);
            setValidationMessage('');
            validator.hideMessages();

            try {
                const response = await axios.post(
                    'https://plflex58kb.execute-api.ap-south-1.amazonaws.com/api/send-mail-service',
                    forms,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        timeout: 60000, // 60 seconds timeout
                    }
                );

                if (response.status === 200) {
                    setIsSuccess(true);
                    clearForm();
                    setTimeout(() => setIsSuccess(false), 4000); // Reset success message after 4 seconds
                }
            } catch (error) {
                console.error('Submission error:', error);
                if (error.response) {
                    console.error('Response Error:', error.response);
                    setValidationMessage(
                        `Error: ${error.response.data.message || 'Submission failed'}`
                    );
                } else if (error.request) {
                    console.error('Request Error:', error.request);
                    setValidationMessage('Failed to connect to the server. Please try again.');
                } else {
                    console.error('General Error:', error.message);
                    setValidationMessage('An unexpected error occurred.');
                }
            } finally {
                setIsLoading(false);
            }
        } else {
            setValidationMessage('Please fill all fields correctly and ensure no field is empty.');
            validator.showMessages();
        }

        // Log the service value on form submission
        console.log('Form submitted with service:', forms.service);
    };

    return (
        <div className="row align-items-center">
            <div className="col-md-6">
                {isSuccess ? (
                    <div className="text-center">
                        <img src={arrow} className="successImage" alt="Success" />
                        <h4>Your message has been submitted successfully!</h4>
                    </div>
                ) : (
                    <form onSubmit={submitHandler} className="form-section">
                        {validationMessage && (
                            <div className="alert alert-danger" role="alert">
                                {validationMessage}
                            </div>
                        )}

                        <div className="form-group">
                            <input
                                value={forms.name}
                                type="text"
                                name="name"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="Full Name"
                                aria-label="Full Name"
                            />
                            {shouldShowError('name') &&
                                validator.message('name', forms.name, 'required|alpha_space')}
                        </div>

                        <div className="form-group">
                            <input
                                value={forms.email}
                                type="email"
                                name="email"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="Email"
                                aria-label="Email"
                            />
                            {shouldShowError('email') &&
                                validator.message('email', forms.email, 'required|email')}
                        </div>

                        <div className="form-group">
                            <input
                                value={forms.phone}
                                type="text"
                                name="phone"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="Phone no"
                                aria-label="Phone Number"
                            />
                            {shouldShowError('phone') &&
                                validator.message('phone', forms.phone, 'required|phone')}
                        </div>

                        <div className="form-group">
                            <input
                                value={forms.company}
                                type="text"
                                name="company"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="Company"
                                aria-label="Company"
                            />
                            {shouldShowError('company') &&
                                validator.message('company', forms.company, 'required|alpha_space')}
                        </div>

                        <div className="form-group">
                            <input
                                value={forms.city}
                                type="text"
                                name="city"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="City"
                                aria-label="City"
                            />
                            {shouldShowError('city') &&
                                validator.message('city', forms.city, 'required|alpha_space')}
                        </div>

                        <div className="form-group">
                            <input
                                value={forms.country}
                                type="text"
                                name="country"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                placeholder="Country"
                                aria-label="Country"
                            />
                            {shouldShowError('country') &&
                                validator.message('country', forms.country, 'required|alpha_space')}
                        </div>

                        <div className="form-group">
                            <select
                                value={forms.service}
                                name="service"
                                className="form-control"
                                onBlur={blurHandler}
                                onChange={changeHandler}
                                aria-label="Service"
                            >
                                <option value="" disabled>
                                    Select Service
                                </option>
                                <option value="ERP">ERP</option>
                                <option value="CRM">CRM</option>
                                <option value="E-Commerce">E-Commerce</option>
                                <option value="Web Development">Web Development</option>
                                <option value="UI/UX Design">UI/UX Design</option>
                                <option value="App Development">App Development</option>
                                <option value="SEO Optimization">SEO Optimization</option>
                                <option value="Digital Market">Digital Market</option>
                            </select>
                            {shouldShowError('service') &&
                                validator.message('service', forms.service, 'required')}
                        </div>

                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn btn-primary pt-3 pb-3"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Submitting...' : 'Submit'}
                            </button>
                            <button
                                type="button"
                                className="btn btn-light pt-3 pb-3 ms-3"
                                onClick={clearForm}
                                disabled={isLoading}
                            >
                                Clear
                            </button>
                        </div>
                    </form>
                )}
            </div>

            <div className="col-md-6">
                <div className="d-flex justify-content-center">
                    <div>
                        <h2 className="pb-2">A Product of</h2>
                        <a
                            href="https://emobomo.com/"
                            target="_blank"
                            className="site_link"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={emobomo}
                                alt="Emobomo"
                                style={{ maxWidth: '150px', marginLeft: '20px' }}
                            />
                        </a>
                        <div>
                            <a
                                href="https://emobomo.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary mt-4"
                            >
                                <span className="btn_label2">Connect</span>
                                <span className="btn_icon">
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                    <i className="fa-solid fa-arrow-up-right"></i>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;
