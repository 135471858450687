import React, { Fragment, useState } from 'react';
import Header from '../components/header';
import Hero3 from '../components/banner';
import Footer from '../components/footer';
import Scrollbar from '../components/scrollbar';
import Modal from '../components/model';
import ModalForm from '../components/modelform';

const HomePage = () => {
    const [showModal, setShowModal] = useState(false); // Modal state
    const [modalContext, setModalContext] = useState(null); // To track which button was clicked

    const openModal = (context) => {
        setModalContext(context);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setModalContext(null);
    };

    return (
        <Fragment>
            <div className="index_business_consulting">
                <main className="page_content s2">
                    <Header openModal={() => openModal("Header Button")} />
                    <Hero3 openModal={openModal} /> {/* Pass openModal function to Hero3 */}
                    {/* <FeaturesSection /> */}
                    {/* <ConsultingService /> */}
                    {/* <BusinessTestimonial /> */}
                    <Footer />
                    <Scrollbar />
                </main>
                {/* Modal */}
                <Modal show={showModal} handleClose={closeModal}>
                    <ModalForm context={modalContext} /> {/* Pass context to ModalForm */}
                </Modal>
            </div>
        </Fragment>
    );
};

export default HomePage;
