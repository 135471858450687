import React, { useState } from 'react';
import sImg1 from '../images/statistics.png';
import sImg2 from '../images/crm.png';
import sImg3 from '../images/coding.png';
import sImg4 from '../images/responsive-design.png';
import sImg5 from '../images/chat.png';
import sImg6 from '../images/social-media.png';
import Appshivefav from '../images/logocenter.png';
import arrow from '../images/curve.gif';

const TechnologyList = [
    {
        Id: '1',
        sIcon: sImg1,
        title: 'ERP',
        slug: 'erp',
        link: '',
    },
    {
        Id: '2',
        sIcon: sImg2,
        title: 'CRM',
        slug: 'crm',
        link: '',
    },
    {
        Id: '3',
        sIcon: sImg3,
        title: 'Build Your Own Website',
        slug: 'website-development',
        link: 'https://build.emobomo.in/',
    },
    {
        Id: '4',
        sIcon: sImg4,
        title: 'UX/UI Design',
        slug: 'ux-ui-development',
        link: '',
    },
    {
        Id: '5',
        sIcon: sImg5,
        title: 'APP Development',
        slug: 'app-development',
        link: '',
    },
    {
        Id: '6',
        sIcon: sImg6,
        title: 'Digital Market',
        slug: 'digital-market',
        link: '',
    },
];

const TechnologySection = ({ openModal }) => {
    const [selectedTechnology, setSelectedTechnology] = useState('');

    const handleClick = (title) => {
        setSelectedTechnology(title);
        console.log(selectedTechnology);
        openModal(title); 
        window.scrollTo(0, 0);
    }; 


    return (
        <div className="section_space">
            <div className="technology-section tab_block_wrapper">
                <div className="center-circle">
                    <h1 className="center-title">
                        <img src={Appshivefav} alt="Appshive Logo" />
                    </h1>
                </div>
                <div className="web_development_technologies outer-circle">
                    {TechnologyList.map((technology, cnt) => (
                        <div
                            className="tech-item"
                            key={cnt}
                            style={{ '--angle': (cnt * 360) / TechnologyList.length }}
                        >
                            <div className="iconbox_block text-center p-0 shadow-none bg-transparent">
                                {technology.link ? (
                                    <a
                                        href={technology.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <div className="iconbox_icon">
                                            <img
                                                src={technology.sIcon}
                                                className="tech-icon"
                                                alt={technology.title}
                                            />
                                        </div>
                                        <img src={arrow} className="arrow" alt="Arrow" />
                                        <div className="iconbox_content">
                                            <h3 className="iconbox_title mb-0">{technology.title}</h3>
                                        </div>
                                    </a>
                                ) : (
                                    <button
                                    onClick={() => handleClick(technology.title)}
                                    
                                    >
                                        <div className="iconbox_icon">
                                            <img
                                                src={technology.sIcon}
                                                className="tech-icon"
                                                alt={technology.title}
                                            />
                                        </div>
                                        <img src={arrow} className="arrow" alt="Arrow" />
                                        <div className="iconbox_content">
                                            <h3 className="iconbox_title mb-0">{technology.title}</h3>
                                        </div>
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default TechnologySection;
